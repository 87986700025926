<template lang="html">
<div class="opis">


<h1>Upitnik za procenu sprovedene edukacije </h1>
<p>Upitnik se popunjava isključivo u svrhu unapređenja kvaliteta sprovođenja edukacija.</p>
<p>Molimo Vas da na sva pitanja odgovorite iskreno, u skladu sa Vašim ličnim utiscima vezano za sprovedenu edukaciju na temu.</p>
<p>Na sledeća pitanja odgovorite zaokruživanjem ocene od 1 do 5, pri čemu je broj 1 najlošija, dok je broj 5 najbolja ocena.</p>

</div>
<div class="row">
  <div class="card mx-auto">
<div class="card-body">

<form @submit.prevent="submit">
    <div class="form-group">
      <h3>1. U kojoj meri ciljevi edukacije odgovaraju Vašim potrebama?</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans1a" id="ans1a" v-model="ans1" value=1>
        <label class="form-check-label" for="flexRadioDefault1">
        1
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans1a" id="ans1a" v-model="ans1" value=2>
        <label class="form-check-label" for="flexRadioDefault1">
        2
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans1a" id="ans1a" v-model="ans1" value=3>
        <label class="form-check-label" for="flexRadioDefault1">
        3
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans1a" id="ans1a" v-model="ans1" value=4>
        <label class="form-check-label" for="flexRadioDefault1">
        4
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans1a" id="ans1a" v-model="ans1" value=5>
        <label class="form-check-label" for="flexRadioDefault1">
        5
        </label>
      </div>
    </div>
    <div class="form-group">
      <h3>2. U kojoj meri je prezentacija teme bila prikladna?</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans2a" id="ans2a" v-model="ans2" value=1>
        <label class="form-check-label" for="flexRadioDefault1">
        1
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans2a" id="ans2a" v-model="ans2" value=2>
        <label class="form-check-label" for="flexRadioDefault1">
        2
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans2a" id="ans2a" v-model="ans2" value=3>
        <label class="form-check-label" for="flexRadioDefault1">
        3
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans2a" id="ans2a" v-model="ans2" value=4>
        <label class="form-check-label" for="flexRadioDefault1">
        4
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans2a" id="ans2a" v-model="ans2" value=5>
        <label class="form-check-label" for="flexRadioDefault1">
        5
        </label>
      </div>
    </div>
    <div class="form-group">
      <h3>3. U kojoj meri ocenjujete stručnost predavača?</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans3a" id="ans3a" v-model="ans3" value=1>
        <label class="form-check-label" for="flexRadioDefault1">
        1
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans3a" id="ans3a" v-model="ans3" value=2>
        <label class="form-check-label" for="flexRadioDefault1">
        2
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans3a" id="ans3a" v-model="ans3" value=3>
        <label class="form-check-label" for="flexRadioDefault1">
        3
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans3a" id="ans3a" v-model="ans3" value=4>
        <label class="form-check-label" for="flexRadioDefault1">
        4
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans3a" id="ans3a" v-model="ans3" value=5>
        <label class="form-check-label" for="flexRadioDefault1">
        5
        </label>
      </div>
    </div>
    <div class="form-group">
      <h3>4. U kojoj meri je predavač bio pripremljen?</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans4a" id="ans4a" v-model="ans4" value=1>
        <label class="form-check-label" for="flexRadioDefault1">
        1
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans4a" id="ans4a" v-model="ans4" value=2>
        <label class="form-check-label" for="flexRadioDefault1">
        2
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans4a" id="ans4a" v-model="ans4" value=3>
        <label class="form-check-label" for="flexRadioDefault1">
        3
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans4a" id="ans4a" v-model="ans4" value=4>
        <label class="form-check-label" for="flexRadioDefault1">
        4
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans4a" id="ans4a" v-model="ans4" value=5>
        <label class="form-check-label" for="flexRadioDefault1">
        5
        </label>
      </div>
    </div>
    <div class="form-group">
      <h3>5. U kojoj meri smatrate da ćete moći da primenite znanje stečeno na ovoj edukaciji?</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans5a" id="ans5a" v-model="ans5" value=1>
        <label class="form-check-label" for="flexRadioDefault1">
        1
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans5a" id="ans5a" v-model="ans5" value=2>
        <label class="form-check-label" for="flexRadioDefault1">
        2
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans5a" id="ans5a" v-model="ans5" value=3>
        <label class="form-check-label" for="flexRadioDefault1">
        3
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans5a" id="ans5a" v-model="ans5" value=4>
        <label class="form-check-label" for="flexRadioDefault1">
        4
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans5a" id="ans5a" v-model="ans5" value=5>
        <label class="form-check-label" for="flexRadioDefault1">
        5
        </label>
      </div>
    </div>
    <div class="form-group">
      <h3>6. Kako ocenjujete kvalitet zadatih vežbi?</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans6a" id="ans6a" v-model="ans6" value=1>
        <label class="form-check-label" for="flexRadioDefault1">
        1
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans6a" id="ans6a" v-model="ans6" value=2>
        <label class="form-check-label" for="flexRadioDefault1">
        2
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans6a" id="ans6a" v-model="ans6" value=3>
        <label class="form-check-label" for="flexRadioDefault1">
        3
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans6a" id="ans6a" v-model="ans6" value=4>
        <label class="form-check-label" for="flexRadioDefault1">
        4
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans6a" id="ans6a" v-model="ans6" value=5>
        <label class="form-check-label" for="flexRadioDefault1">
        5
        </label>
      </div>
    </div>
    <div class="form-group">
      <h3>7. Kako ocenjujete izvođenje predavača?</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans7a" id="ans7a" v-model="ans7" value=1>
        <label class="form-check-label" for="flexRadioDefault1">
        1
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans7a" id="ans7a" v-model="ans7" value=2>
        <label class="form-check-label" for="flexRadioDefault1">
        2
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans7a" id="ans7a" v-model="ans7" value=3>
        <label class="form-check-label" for="flexRadioDefault1">
        3
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans7a" id="ans7a" v-model="ans7" value=4>
        <label class="form-check-label" for="flexRadioDefault1">
        4
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans7a" id="ans7a" v-model="ans7" value=5>
        <label class="form-check-label" for="flexRadioDefault1">
        5
        </label>
      </div>
    </div>
    <div class="form-group">
      <h3>8. Kako ocenjujete prezentacione veštine predavača?</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans8a" id="ans8a" v-model="ans8" value=1>
        <label class="form-check-label" for="flexRadioDefault1">
        1
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans8a" id="ans8a" v-model="ans8" value=2>
        <label class="form-check-label" for="flexRadioDefault1">
        2
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans8a" id="ans8a" v-model="ans8" value=3>
        <label class="form-check-label" for="flexRadioDefault1">
        3
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans8a" id="ans8a" v-model="ans8" value=4>
        <label class="form-check-label" for="flexRadioDefault1">
        4
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans8a" id="ans8a" v-model="ans8" value=5>
        <label class="form-check-label" for="flexRadioDefault1">
        5
        </label>
      </div>
    </div>
    <div class="form-group">
      <h3>9. Kako ocenjujete navedene primere tokom edukacije?</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans9a" id="ans9a" v-model="ans9" value=1>
        <label class="form-check-label" for="flexRadioDefault1">
        1
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans9a" id="ans9a" v-model="ans9" value=2>
        <label class="form-check-label" for="flexRadioDefault1">
        2
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans9a" id="ans9a" v-model="ans9" value=3>
        <label class="form-check-label" for="flexRadioDefault1">
        3
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans9a" id="ans9a" v-model="ans9" value=4>
        <label class="form-check-label" for="flexRadioDefault1">
        4
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans9a" id="ans9a" v-model="ans9" value=5>
        <label class="form-check-label" for="flexRadioDefault1">
        5
        </label>
      </div>
    </div>
    <div class="form-group">
      <h3>10. Navedite teme za koje smatrate da su bile nevažne za edukaciju i obrazložite odgovor:</h3>
      <div class="">
        <textarea class="form-control" id="exampleFormControlTextarea1" rows="5" v-model="ans10" ></textarea>
      </div>

    </div>
    <div class="form-group">
      <h3>11. Navedite dodatne teme za koje mislite da bi trebalo da se uključe u edukacije:</h3>
      <div class="">
        <textarea class="form-control" id="exampleFormControlTextarea1" rows="5" v-model="ans11" ></textarea>
      </div>
    </div>


  <button class="btn btn-primary">Submit</button>

</form>
</div>
  </div>
  </div>

</template>

<script>
import {mapActions,mapGetters} from 'vuex';

export default {
  computed: mapGetters(['user','companys']),
  data(){
    return{
      ans1:1,
      ans2:1,
      ans3:1,
      ans4:1,
      ans5:1,
      ans6:1,
      ans7:1,
      ans8:1,
      ans9:2,
      ans10:"",
      ans11:"",
      tempCompany:{},
    };
  },
  methods:{
    ...mapActions(['submitVQ','getProfile','getCompanies','fillEducationEval','administerProcenaEdukacijeDeny']),
    async submit(){


      let oldProcena = this.tempCompany.upitnikEdukacija[this.tempCompany.upitnikEdukacija.length-1]



      console.log("baza:"+oldProcena.ans1);
      console.log("oldProcena.ans1*oldProcena.submisions:"+oldProcena.ans1*oldProcena.submisions);
      console.log("(oldProcena.ans1*oldProcena.submisions + this.ans1 ):"+(oldProcena.ans1*oldProcena.submisions + this.ans1 ));
      console.log("(oldProcena.submisions+1):"+(oldProcena.submisions+1));
      console.log("sve:"+(oldProcena.ans1*oldProcena.submisions + parseInt(this.ans1) ) / (oldProcena.submisions+1));
      oldProcena.ans1 = (oldProcena.ans1*oldProcena.submisions + parseInt(this.ans1) ) / (oldProcena.submisions+1);
      oldProcena.ans2 = (oldProcena.ans2*oldProcena.submisions + parseInt(this.ans2) ) / (oldProcena.submisions+1);
      oldProcena.ans3 = (oldProcena.ans3*oldProcena.submisions + parseInt(this.ans3) ) / (oldProcena.submisions+1);
      oldProcena.ans4 = (oldProcena.ans4*oldProcena.submisions + parseInt(this.ans4) ) / (oldProcena.submisions+1);
      oldProcena.ans5 = (oldProcena.ans5*oldProcena.submisions + parseInt(this.ans5) ) / (oldProcena.submisions+1);
      oldProcena.ans6 = (oldProcena.ans6*oldProcena.submisions + parseInt(this.ans6) ) / (oldProcena.submisions+1);
      oldProcena.ans7 = (oldProcena.ans7*oldProcena.submisions + parseInt(this.ans7) ) / (oldProcena.submisions+1);
      oldProcena.ans8 = (oldProcena.ans8*oldProcena.submisions + parseInt(this.ans8) ) / (oldProcena.submisions+1);
      oldProcena.ans9 = (oldProcena.ans9*oldProcena.submisions + parseInt(this.ans9) ) / (oldProcena.submisions+1);
      oldProcena.ans10 = oldProcena.ans10+this.ans10+"&&";
      oldProcena.ans11 = oldProcena.ans11+this.ans11+"&&";
      oldProcena.submisions=oldProcena.submisions+1;

      console.log(oldProcena);
      let data={
        procena:oldProcena,
        companyName:this.tempCompany.name
      }

      await this.fillEducationEval(data);
      await this.administerProcenaEdukacijeDeny(this.user._id)
      .then(res=>{
        if(res.data.success){
          this.$router.push('/profile');
        }
      }).catch(err =>{
        console.log(err);
      });
    // this.submitVQ(vqData)
    // .then(res=>{
    //   if(res.data.success){
    //     this.$router.push('/profile');
    //   }
    // }).catch(err =>{
    //   console.log(err);
    // });



    }
  },
async   created(){
    await this.getCompanies();

    for (var i = 0; i < this.companys.length; i++) {
      if(this.companys[i].name==this.user.type){
        this.tempCompany=this.companys[i];
      }
    }

  },

};
</script>

<style lang="css" scoped>

.opis{
  background-color: #bbffff;
  padding: 15px;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-radius: 15px;
}
.form-check{
  display: inline-block;
  margin-right: 15px;
}
.form-group{
  background-color: #bbffbb;
  padding: 15px;
}
.form-group:nth-child(2){
  background-color: #99dd99;
  padding: 15px;
}
.form-group:nth-child(4){
  background-color: #99dd99;
  padding: 15px;
}
.form-group:nth-child(6){
  background-color: #99dd99;
  padding: 15px;
}
.form-group:nth-child(8){
  background-color: #99dd99;
  padding: 15px;
}
.form-group:nth-child(10){
  background-color: #99dd99;
  padding: 15px;
}
.form-group:nth-child(12){
  background-color: #99dd99;
  padding: 15px;
}
.form-group:nth-child(14){
  background-color: #99dd99;
  padding: 15px;
}
.form-group:nth-child(16){
  background-color: #99dd99;
  padding: 15px;
}
.form-group:nth-child(18){
  background-color: #99dd99;
  padding: 15px;
}
.form-group:nth-child(20){
  background-color: #99dd99;
  padding: 15px;
}
.form-group:nth-child(22){
  background-color: #99dd99;
  padding: 15px;
}
.form-group:nth-child(24){
  background-color: #99dd99;
  padding: 15px;
}
.btn{
  width: 100%;
  background-color: #003366;
}
</style>
